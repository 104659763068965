/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FormWaiversAndNgbWaiversResponse } from '../models/FormWaiversAndNgbWaiversResponse';
import type { NgbDivisionsResponse } from '../models/NgbDivisionsResponse';
import type { NgbMemberResponse } from '../models/NgbMemberResponse';
import type { ValidateMembershipRequest } from '../models/ValidateMembershipRequest';
import type { ValidateMembershipResponse } from '../models/ValidateMembershipResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NgbIntegrationsService {

    /**
     * Validates membership for a specific NGB integration
     * @param ngbIntegrationId
     * @param requestBody
     * @returns ValidateMembershipResponse Success
     * @throws ApiError
     */
    public static validateMembership(
        ngbIntegrationId: number,
        requestBody?: ValidateMembershipRequest,
    ): CancelablePromise<ValidateMembershipResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ngb-integrations/{ngbIntegrationId}/validate-membership',
            path: {
                'ngbIntegrationId': ngbIntegrationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Gets ngb waivers for a specific NGB integration and sync them in a dynamic way based on the organization, period season dates and form id
     * @param ngbIntegrationId
     * @param organizationId
     * @param formId
     * @param seasonStartDate
     * @param seasonEndDate
     * @returns FormWaiversAndNgbWaiversResponse Success
     * @throws ApiError
     */
    public static getNgbWaivers(
        ngbIntegrationId: number,
        organizationId: number,
        formId: number,
        seasonStartDate: string,
        seasonEndDate: string,
    ): CancelablePromise<FormWaiversAndNgbWaiversResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ngb-integrations/{ngbIntegrationId}/get-ngb-waivers',
            path: {
                'ngbIntegrationId': ngbIntegrationId,
            },
            query: {
                'organizationId': organizationId,
                'formId': formId,
                'seasonStartDate': seasonStartDate,
                'seasonEndDate': seasonEndDate,
            },
        });
    }

    /**
     * Gets ngb member for a specific NGB integration and a specific person uuid
     * @param ngbIntegrationId
     * @param personUuid
     * @returns NgbMemberResponse Success
     * @throws ApiError
     */
    public static getNgbMember(
        ngbIntegrationId: number,
        personUuid?: string,
    ): CancelablePromise<NgbMemberResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ngb-integrations/{ngbIntegrationId}/get-ngb-member',
            path: {
                'ngbIntegrationId': ngbIntegrationId,
            },
            query: {
                'personUuid': personUuid,
            },
        });
    }

    /**
     * Gets ngb divisions for a specific NGB integration and based on the organization and member id
     * @param ngbIntegrationId
     * @param memberId
     * @param organizationId
     * @returns NgbDivisionsResponse Success
     * @throws ApiError
     */
    public static getNgbAvailableMemberDivisions(
        ngbIntegrationId: number,
        memberId: string,
        organizationId: number,
    ): CancelablePromise<NgbDivisionsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ngb-integrations/{ngbIntegrationId}/get-available-member-divisions',
            path: {
                'ngbIntegrationId': ngbIntegrationId,
            },
            query: {
                'memberId': memberId,
                'organizationId': organizationId,
            },
        });
    }

}
