/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdjustmentResults } from '../models/AdjustmentResults';
import type { FieldResult } from '../models/FieldResult';
import type { FieldResults } from '../models/FieldResults';
import type { FieldsUpdated } from '../models/FieldsUpdated';
import type { Registration } from '../models/Registration';
import type { RegistrationCreate } from '../models/RegistrationCreate';
import type { RegistrationUpdate } from '../models/RegistrationUpdate';
import type { RegistrationWaiver } from '../models/RegistrationWaiver';
import type { RegistrationWaiverCreate } from '../models/RegistrationWaiverCreate';
import type { RegistrationWaiverUpdate } from '../models/RegistrationWaiverUpdate';
import type { RegistrationWithDiscounts } from '../models/RegistrationWithDiscounts';
import type { SignedUploadUrl } from '../models/SignedUploadUrl';
import type { SignedUrl } from '../models/SignedUrl';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RegistrationsService {

    /**
     * Returns registrations for the current user given with discounts for a form id
     * @param formId
     * @param couponCodes
     * @param status
     * @returns RegistrationWithDiscounts Success
     * @throws ApiError
     */
    public static getRegistrationsWithDiscounts(
        formId?: number,
        couponCodes?: string,
        status?: string,
    ): CancelablePromise<RegistrationWithDiscounts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/registrations-with-discounts',
            query: {
                'formId': formId,
                'couponCodes': couponCodes,
                'status': status,
            },
        });
    }

    /**
     * Creates a new registration for the specified form
     * @param requestBody
     * @returns Registration Success
     * @throws ApiError
     */
    public static createRegistration(
        requestBody?: RegistrationCreate,
    ): CancelablePromise<Registration> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/registrations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Error`,
            },
        });
    }

    /**
     * Returns registrations for the current user
     * @param status
     * @returns Registration Success
     * @throws ApiError
     */
    public static getRegistrations(
        status?: string,
    ): CancelablePromise<Array<Registration>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/registrations',
            query: {
                'status': status,
            },
        });
    }

    /**
     * Updates an existing waiver signature for the registration
     * @param registrationId
     * @param formWaiverResultId
     * @param requestBody
     * @returns RegistrationWaiver Success
     * @throws ApiError
     */
    public static updateRegistrationWaiver(
        registrationId: number,
        formWaiverResultId: number,
        requestBody?: RegistrationWaiverUpdate,
    ): CancelablePromise<RegistrationWaiver> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/registrations/{registrationId}/waivers/{formWaiverResultId}',
            path: {
                'registrationId': registrationId,
                'formWaiverResultId': formWaiverResultId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Loads the waiver results for a registration
     * @param registrationId
     * @returns RegistrationWaiver Success
     * @throws ApiError
     */
    public static getWaiverResults(
        registrationId: number,
    ): CancelablePromise<Array<RegistrationWaiver>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/registrations/{registrationId}/waivers',
            path: {
                'registrationId': registrationId,
            },
        });
    }

    /**
     * Creates a new waiver acceptance for the registration
     * @param registrationId
     * @param requestBody
     * @returns RegistrationWaiver Success
     * @throws ApiError
     */
    public static createRegistrationWaiver(
        registrationId: number,
        requestBody?: RegistrationWaiverCreate,
    ): CancelablePromise<RegistrationWaiver> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/registrations/{registrationId}/waivers',
            path: {
                'registrationId': registrationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Returns a signed url for uploading a file
     * @param registrationId
     * @param formFieldId
     * @param fileExtension
     * @returns SignedUploadUrl Success
     * @throws ApiError
     */
    public static getFieldSignupUploadUrl(
        registrationId: number,
        formFieldId: number,
        fileExtension: string,
    ): CancelablePromise<SignedUploadUrl> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/registrations/{registrationId}/fields/{formFieldId}/upload-url',
            path: {
                'registrationId': registrationId,
                'formFieldId': formFieldId,
            },
            query: {
                'fileExtension': fileExtension,
            },
        });
    }

    /**
     * Loads the fields results for a registration
     * @param registrationId
     * @param formFieldId
     * @returns SignedUrl Success
     * @throws ApiError
     */
    public static getFieldSignupDownloadUrl(
        registrationId: number,
        formFieldId: number,
    ): CancelablePromise<SignedUrl> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/registrations/{registrationId}/fields/{formFieldId}/download-url',
            path: {
                'registrationId': registrationId,
                'formFieldId': formFieldId,
            },
        });
    }

    /**
     * Loads the fields results for a registration
     * @param registrationId
     * @returns FieldResult Success
     * @throws ApiError
     */
    public static getFieldResults(
        registrationId: number,
    ): CancelablePromise<Array<FieldResult>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/registrations/{registrationId}/fields',
            path: {
                'registrationId': registrationId,
            },
        });
    }

    /**
     * Saves values for fields
     * @param registrationId
     * @param requestBody
     * @returns FieldsUpdated Success
     * @throws ApiError
     */
    public static saveFieldResults(
        registrationId: number,
        requestBody?: FieldResults,
    ): CancelablePromise<FieldsUpdated> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/registrations/{registrationId}/fields',
            path: {
                'registrationId': registrationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Loads the adjustment results for a registration
     * @param registrationId
     * @returns AdjustmentResults Success
     * @throws ApiError
     */
    public static getAdjustmentResults(
        registrationId: number,
    ): CancelablePromise<AdjustmentResults> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/registrations/{registrationId}/adjustments',
            path: {
                'registrationId': registrationId,
            },
        });
    }

    /**
     * Saves adjustment results
     * @param registrationId
     * @param requestBody
     * @returns AdjustmentResults Success
     * @throws ApiError
     */
    public static saveAdjustmentResults(
        registrationId: number,
        requestBody?: AdjustmentResults,
    ): CancelablePromise<AdjustmentResults> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/registrations/{registrationId}/adjustments',
            path: {
                'registrationId': registrationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Sets the registration to waitlisted
     * @param registrationId
     * @returns Registration Success
     * @throws ApiError
     */
    public static addToWaitlist(
        registrationId: number,
    ): CancelablePromise<Registration> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/registrations/{registrationId}/add-to-waitlist',
            path: {
                'registrationId': registrationId,
            },
        });
    }

    /**
     * Updates an existing registration
     * @param registrationId
     * @param requestBody
     * @returns Registration Success
     * @throws ApiError
     */
    public static updateRegistration(
        registrationId: number,
        requestBody?: RegistrationUpdate,
    ): CancelablePromise<Registration> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/registrations/{registrationId}',
            path: {
                'registrationId': registrationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
