import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AdjustmentResult,
  FieldResults,
  FieldsUpdated,
  Registration,
  RegistrationsService,
  RegistrationUpdate,
  RegistrationWaiverCreate,
  RegistrationWaiverUpdate,
} from 'core/api';
import {
  RegistrationCreateAction,
  RegistrationLoadFieldSignupUploadUrl,
  RegistrationState,
  RegistrationUpdateAction,
} from './types';
import { AsyncState } from 'core/types';

import {
  asyncFulfilledNoDataReducer,
  asyncFulfilledSubReducer,
  asyncPendingReducer,
  handleErrors,
  useAppSelector,
} from 'state/hooks';
import { RootState } from '../store';

const initialState: AsyncState<RegistrationState> = {
  data: null,
  processing: false,
  error: false,
  errors: [],
};

export const loadRegistrations = createAsyncThunk('registrations/load', () =>
  handleErrors(() => RegistrationsService.getRegistrations('in_progress,in_cart'))
);

export const loadRegistrationsWithDiscounts = createAsyncThunk(
  'registrations/loadDiscounts',
  (data: { formId: number; couponCodes: string }) =>
    handleErrors(() => RegistrationsService.getRegistrationsWithDiscounts(data.formId, data.couponCodes, 'in_cart'))
);

export const createRegistration = createAsyncThunk(
  'registration/create',
  async (data: RegistrationCreateAction, thunkApi) => {
    const { householdPersonId, participantGroupId, isWaitlisted } = data;

    return handleErrors(async () => {
      const result = await RegistrationsService.createRegistration({
        householdPersonId,
        participantGroupId,
        isWaitlisted,
      });
      if (result) {
        await thunkApi.dispatch(
          updateRegistration({
            currentRegistration: result,
          })
        );
        await thunkApi.dispatch(loadRegistrations());
      }
    });
  }
);

export const updateRegistrationStatus = createAsyncThunk(
  'registration/updateStatus',
  async (data: RegistrationUpdateAction, thunkApi) => {
    const { registrationId, body } = data;

    return handleErrors(async () => {
      const result = await RegistrationsService.updateRegistration(registrationId, body);
      if (result) {
        await thunkApi.dispatch(loadRegistrations());
      }
    });
  }
);

export const addToWaitlist = createAsyncThunk(
  'registration/addToWaitlist',
  async (data: { registrationId: number }, thunkApi) => {
    const { registrationId } = data;

    return handleErrors(async () => {
      const result = await RegistrationsService.addToWaitlist(registrationId);

      if (result) {
        await thunkApi.dispatch(loadRegistrations());
      }
    });
  }
);

export const loadWaiverResults = createAsyncThunk('waiverResults/load', (registrationId: number) =>
  handleErrors(() => RegistrationsService.getWaiverResults(registrationId))
);

export const createWaiverResult = createAsyncThunk(
  'waiverResults/create',
  async (data: { registrationId: number; body: RegistrationWaiverCreate }, thunkApi) => {
    const { registrationId, body } = data;

    return handleErrors(async () => {
      const result = await RegistrationsService.createRegistrationWaiver(registrationId, body);
      if (result) {
        await thunkApi.dispatch(loadWaiverResults(registrationId));
      }
      return result;
    });
  }
);

export const updateWaiverResult = createAsyncThunk(
  'waiverResults/update',
  async (data: { registrationId: number; formWaiverResultId: number; body: RegistrationWaiverUpdate }, thunkApi) => {
    const { registrationId, formWaiverResultId, body } = data;

    return handleErrors(async () => {
      const result = await RegistrationsService.updateRegistrationWaiver(registrationId, formWaiverResultId, body);
      if (result) {
        await thunkApi.dispatch(loadWaiverResults(registrationId));
      }
      return result;
    });
  }
);

export const loadFieldSignupUploadUrl = createAsyncThunk(
  'fieldSignupUploadUrl/load',
  (data: RegistrationLoadFieldSignupUploadUrl) => {
    const { registrationId, formFieldId, fileExtension } = data;

    return handleErrors(() => RegistrationsService.getFieldSignupUploadUrl(registrationId, formFieldId, fileExtension));
  }
);

export const loadFieldResults = createAsyncThunk('fieldResults/load', (registrationId: number) =>
  handleErrors(async () => {
    const results = await RegistrationsService.getFieldResults(registrationId);
    return { success: results, failure: [] } as FieldsUpdated;
  })
);

export const saveFieldResults = createAsyncThunk(
  'fieldResults/save',
  (data: { registrationId: number; body: FieldResults }) =>
    handleErrors(() => RegistrationsService.saveFieldResults(data.registrationId, data.body))
);

export const loadFieldAdjustmentResults = createAsyncThunk('fieldAdjustmentResults/load', (registrationId: number) =>
  handleErrors(async () => (await RegistrationsService.getAdjustmentResults(registrationId)).adjustmentResults)
);

export const saveFieldAdjustmentResults = createAsyncThunk(
  'fieldAdjustmentResults/save',
  (data: { registrationId: number; body: AdjustmentResult[] }, thunkApi) => {
    return handleErrors(async () => {
      const result = await RegistrationsService.saveAdjustmentResults(data.registrationId, {
        adjustmentResults: data.body,
      });
      if (result) {
        await thunkApi.dispatch(loadRegistrations());
      }
      return result.adjustmentResults as AdjustmentResult[];
    });
  }
);

export const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    updateRegistration: (state, action: PayloadAction<Partial<RegistrationState>>) => {
      const newState = {
        ...state.data,
        ...action.payload,
      };
      state.data = newState;
    },
    addSubmittedRegistration: (state, action: PayloadAction<Registration>) => {
      const temp = state.data?.submittedRegistrations ?? [];
      const newState = {
        ...state.data,
        submittedRegistrations: [...temp, action.payload],
      };
      state.data = newState;
    },
  },
  extraReducers: (builder) => {
    // Load Registration
    builder.addCase(loadRegistrations.pending, asyncPendingReducer);
    builder.addCase(loadRegistrations.fulfilled, (state, action) =>
      asyncFulfilledSubReducer(state, action, 'registrations')
    );

    // Load Registrations with Discounts
    // The action type does not match what is coming from the backend, so I cannot use
    //    asyncFulfilledSubReducer(state, action, 'registrationsWithDiscounts')
    // We may need to update the backend to make this flow seamlessly like the others do
    builder.addCase(loadRegistrationsWithDiscounts.pending, asyncPendingReducer);
    builder.addCase(loadRegistrationsWithDiscounts.fulfilled, (state, action) => {
      state.processing = false;

      if (action.payload.data) {
        const updatedState = {
          ...state.data,
          registrationsWithDiscounts: [
            {
              registrations: action.payload.data.registrations,
              discounts: action.payload.data.discounts,
            },
          ],
        };

        state.data = updatedState;
      }
    });

    // Create Registration
    builder.addCase(createRegistration.pending, asyncPendingReducer);
    builder.addCase(createRegistration.fulfilled, asyncFulfilledNoDataReducer);

    // Update Registration
    builder.addCase(updateRegistrationStatus.pending, asyncPendingReducer);
    builder.addCase(updateRegistrationStatus.fulfilled, (state, action) => {
      asyncFulfilledNoDataReducer(state, action);
      if (action.payload.error) {
        state.error = true;
        state.errors = action.payload.errors;
      }
    });

    // Add to Waitlist
    builder.addCase(addToWaitlist.pending, asyncPendingReducer);
    builder.addCase(addToWaitlist.fulfilled, asyncFulfilledNoDataReducer);

    // Load Waiver Results
    builder.addCase(loadWaiverResults.pending, asyncPendingReducer);
    builder.addCase(loadWaiverResults.fulfilled, (state, action) => {
      const temp = state?.data?.waiverResults ?? [];
      action?.payload?.data?.forEach((newResult) => {
        const index = temp?.findIndex((wr) => wr.id === newResult.id);
        if (temp) {
          if (index === -1) {
            temp?.push(newResult);
          } else {
            temp[index] = newResult;
          }
        }
      });

      const updatedState = {
        ...state.data,
        waiverResults: temp,
      } as RegistrationState;

      state.data = updatedState;

      if (action.payload.error) {
        state.error = true;
        state.errors = action.payload.errors || ['Unknown Error'];
      }

      state.processing = false;
    });
    // Create Waiver Results
    builder.addCase(createWaiverResult.pending, asyncPendingReducer);
    builder.addCase(createWaiverResult.fulfilled, asyncFulfilledNoDataReducer);

    // Update Waiver Results
    builder.addCase(updateWaiverResult.pending, asyncPendingReducer);
    builder.addCase(updateWaiverResult.fulfilled, asyncFulfilledNoDataReducer);

    // Load Field Signup Upload Url
    builder.addCase(loadFieldSignupUploadUrl.pending, asyncPendingReducer);
    builder.addCase(loadFieldSignupUploadUrl.fulfilled, (state, action) =>
      asyncFulfilledSubReducer(state, action, 'fieldSignupUploadUrl')
    );

    // Load Field Results
    builder.addCase(loadFieldResults.pending, asyncPendingReducer);
    builder.addCase(loadFieldResults.fulfilled, (state, action) =>
      asyncFulfilledSubReducer(state, action, 'fieldResults')
    );

    // Save Field Results
    builder.addCase(saveFieldResults.pending, asyncPendingReducer);
    builder.addCase(saveFieldResults.fulfilled, (state, action) =>
      asyncFulfilledSubReducer(state, action, 'fieldResults')
    );

    // Load Adjustment Results
    builder.addCase(loadFieldAdjustmentResults.pending, asyncPendingReducer);
    builder.addCase(loadFieldAdjustmentResults.fulfilled, (state, action) =>
      asyncFulfilledSubReducer(state, action, 'fieldAdjustmentResults')
    );

    // Save Adjustment Results
    builder.addCase(saveFieldAdjustmentResults.pending, asyncPendingReducer);
    builder.addCase(saveFieldAdjustmentResults.fulfilled, (state, action) =>
      asyncFulfilledSubReducer(state, action, 'fieldAdjustmentResults')
    );
  },
});

export const { updateRegistration, addSubmittedRegistration } = registrationSlice.actions;

export const useRegistrationStateSelector = () => useAppSelector((state) => state.registration);
export const useRegistrationSelector = () => useAppSelector((state) => state.registration.data);
export const useRegistrationRegistrationsSelector = () =>
  useAppSelector((state) => state.registration.data?.registrations || []);

export const inCartRegistrationsSelector = (state: RootState) =>
  state.registration.data?.registrations?.filter(
    (registration) =>
      state.form?.data?.currentForm?.id === registration.formId &&
      registration.status === RegistrationUpdate.status.IN_CART
  ) || [];

export const useRegistrationsWithDiscountsSelector = () =>
  useAppSelector((state) => {
    if (
      state.registration.data?.registrationsWithDiscounts &&
      state.registration.data?.registrationsWithDiscounts?.length > 0
    ) {
      return state.registration.data?.registrationsWithDiscounts[0];
    }

    return [];
  });

export const useInCartRegistrationsSelector = () => useAppSelector(inCartRegistrationsSelector);

export const useWaiversSelector = () => useAppSelector((state) => state.registration.data?.waiverResults);
export const useFieldSignupUploadUrlSelector = () =>
  useAppSelector((state) => state.registration.data?.fieldSignupUploadUrl);

export const useHasSubmitted = () =>
  useAppSelector(
    (state) =>
      state.registration.data?.submittedRegistrations && state.registration.data?.submittedRegistrations.length > 0
  );
export const useRegistrationsLoadedSelector = () => useAppSelector((state) => !!state.registration.data);
export const useGetSubmitted = () => useAppSelector((state) => state.registration.data?.submittedRegistrations);
export const useCurrentMemberSelector = () => useAppSelector((state) => state.registration.data?.currentMember);
export const useCurrentGroupSelector = () => useAppSelector((state) => state.registration.data?.currentGroup);
export const useCurrentRegistration = () => useAppSelector((state) => state.registration.data?.currentRegistration);
export const useCurrentMemberVerified = () => useAppSelector((state) => state.registration.data?.currentMemberVerified);
export const useCurrentFieldResults = () => useAppSelector((state) => state.registration.data?.fieldResults?.success);
export const useCurrentFieldErrors = () => useAppSelector((state) => state.registration.data?.fieldResults?.failure);
export const useCurrentFieldAdjustmentResults = () =>
  useAppSelector((state) => state.registration.data?.fieldAdjustmentResults);
export const useCurrentFormRegistrations = () =>
  useAppSelector((state) => {
    return state.registration.data?.registrations?.filter((reg) => reg.formId === state.form.data?.currentForm?.id);
  });

export const useRegistrationStateErrorSelector = () => useAppSelector((state) => state.registration.error);
export const useRegistrationStateErrorsSelector = () => useAppSelector((state) => state.registration.errors);

export default registrationSlice.reducer;
